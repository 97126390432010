// @font-face {
//     font-family: Montserrat;
//     src: local('Montserrat'), url(./fonts/Montserrat-VF.ttf);
// }

@import 'normalize.scss';
@import 'variables.scss';
@import 'icons.scss';
@import 'playerVideo.scss';
@import 'mixins.scss';
@import 'print.scss';

* {
    margin: 0;
    outline-color: $couleur-secondaire-regular;
    padding: 0;
}

::-moz-selection {
    /* Code for Firefox */
    background: $couleur-secondaire-regular;
    color: $couleur-base-blanc;
}

::selection {
    background: $couleur-secondaire-regular;
    color: $couleur-base-blanc;
}

/* CUIS-771 : Needed for scroll to top after changing route (NextJS) */
/* more informations : https://github.com/vercel/next.js/issues/28778 */
html {
  height: 100%;
}

body,
html {
    background-color: $couleur-body-bg;
    color: $couleur-text-regular;
    font-family: futura-pt, sans-serif;
    font-size: 16px;
    line-height: 22px;
    min-width: 300px;
    padding-right: 0 !important; /* @reach/dialog react-remove-scroll => Prevent scoll fake on pages */
    overscroll-behavior: none;
    scroll-behavior: smooth;

    @media #{$mq-medium-and-over} {
        font-size: 18px;
        line-height: 24px;
    }
}

body.noScroll {
    overflow: hidden;
}

input {
    padding: 0.5em;
}

h1 {
    @include titleH1;
}

h2 {
    @include titleH2;
}

h3 {
    @include titleH3;
}

h4 {
    @include titleH4;
}

h5 {
    @include titleH5;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    a {
        font-weight: normal;
    }
}

p {
    font-size: $font-size-base;
    // font-weight: 500;
    margin: 0 0 1rem;
    line-height: 1.5;

    a {
        font-weight: 500;
    }
}

a {
    color: $couleur-primaire-dark;
    text-decoration: none;
    font-weight: 700;
    &:hover {
        text-decoration: underline;
    }
}

strong,
b {
    font-family: futura-pt-bold, sans-serif;
    font-weight: 700;
}

i,
em {
    font-style: italic;
}

ul,
ol {
    margin-left: 3rem;
    li {
        margin-bottom: 0.6em;
    }
}

button,
[type='button'],
[type='reset'],
[type='submit'] {
    -webkit-appearance: button;
    border: 0;
    border: none;
    background: none;
    color: $couleur-primaire-regular;
    cursor: pointer;
    font-family: futura-pt, sans-serif;
    font-size: $font-size-normal;
    font-weight: 500;
    text-decoration: none;
    &:hover {
        color: $couleur-primaire-dark;
        text-decoration: none;
    }
}

.wysiwyg {
    .lead {
        font-family: futura-pt-bold, sans-serif;
        font-weight: 700;
        line-height: 1.8rem;
    }
    h2 {
        font-size: $font-size-l;
        margin-bottom: 0.5rem;
    }
    h3 {
        font-size: $font-size-normal;
    }
    a {
        color: $couleur-primaire-regular;
    }
}

/**
 * Remove the inner border and padding in Firefox.
 */

button::-moz-focus-inner,
[type='button']::-moz-focus-inner,
[type='reset']::-moz-focus-inner,
[type='submit']::-moz-focus-inner {
    border-style: none;
    padding: 0;
}

/**
 * Restore the focus styles unset by the previous rule.
 */

button:-moz-focusring,
[type='button']:-moz-focusring,
[type='reset']:-moz-focusring,
[type='submit']:-moz-focusring {
    outline: none;
}

.contentDark {
    background-color: $couleur-neutre-dark;
    padding: 2em;
    .content {
        display: flex;
        img {
            margin-right: 1em;
            width: 40%;
        }
        p {
            font-size: 1rem;
        }
    }
}

.body {
    margin: 0 auto;
    max-width: calc(1156px - #{$grid-column-gap-mobile} - #{$grid-column-gap-mobile});
    padding: 0 $grid-column-gap-mobile;

    @media #{$mq-body-and-over} {
        max-width: 1152px;
        padding: 0;
    }
}

main.body {
    margin-bottom: 50px;
    padding: 0 $grid-column-gap-mobile;

    @media #{$mq-medium-and-over} {
        padding: 0 0.5rem;
    }

    @media #{$mq-body-and-over} {
        padding: 0;
    }
}

.section {
    margin: 0 auto 2rem;
    overflow: hidden;
    width: 100%;
}

.sousTitresBorders {
    border-bottom: 1px solid $couleur-accent-regular;
    border-top: 1px solid $couleur-accent-regular;
    margin-bottom: 1.5rem;
    margin-top: 1.5rem;
    padding: 0.5rem 0;
}

@media (prefers-reduced-motion: reduce) {
    *,
    *::before,
    *::after {
        animation-duration: 0.01ms !important;
        animation-iteration-count: 1 !important;
        transition-duration: 0.01ms !important;
        scroll-behavior: auto !important;
    }
}

@media speech {
    *:focus {
        outline: auto;
    }
}

// For elements only for screen readers
.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border: 0;
}

:global(.TqVpContainer) {
  min-width: 0;
}
