@font-face {
  font-family: 'icomoon';
  src:  url('./fonts//icomoon.eot?lt9t2c');
  src:  url('./fonts/icomoon.eot?lt9t2c#iefix') format('embedded-opentype'),
    url('./fonts/icomoon.ttf?lt9t2c') format('truetype'),
    url('./fonts/icomoon.woff?lt9t2c') format('woff'),
    url('./fonts/icomoon.svg?lt9t2c#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-accueil:before {
    content: "\e90f";
}
.icon-accueil_chemine:before {
    content: "\e910";
}
.icon-delete:before {
    content: "\e90e";
}
.icon-filtres:before {
    content: "\e90d";
}
.icon-imagotype:before {
  content: "\e900";
}
.icon-logo_carre:before {
  content: "\e901";
}
.icon-logo_rectangle:before {
  content: "\e902";
}
.icon-coeur:before {
  content: "\e903";
}
.icon-coeur_cercle:before {
  content: "\e904";
}
.icon-etoile:before {
  content: "\e905";
}
.icon-fermer:before {
  content: "\e906";
}
.icon-fleche:before {
  content: "\e907";
}
.icon-fleche-left:before {
  content: "\e907";
}
.icon-fleche-right:before {
  content: "\e907";
}
.icon-fleche-down:before {
  content: "\e907";
}
.icon-fleche-up:before {
  content: "\e907";
}
.icon-loupe:before {
  content: "\e908";
}
.icon-partage:before {
  content: "\e909";
}
.icon-profil:before {
  content: "\e90a";
}
.icon-courriel_cercle:before {
  content: "\e90b";
}
.icon-impresion_cercle:before {
  content: "\e90c";
}
