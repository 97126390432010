@mixin dropdown($width: 450px, $direction: left) {
    background-color: white;
    border: 1px solid #d7d7d7;
    border-radius: 0.25rem;
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.25);
    display: block;
    margin-top: 0.6rem;
    max-height: calc(100vh - 152px);
    padding: 0.75rem;
    position: absolute;
    width: $width;
    z-index: 50;

    @if $direction == left {
        left: 0;
    } @else if $direction == right {
        right: 0;
    } @else {
        @error "Unknown direction #{$direction}.";
    }

    &.left {
        left: 0;
        right: auto;
        &:after {
            left: 20px;
            right: auto;
        }
    }

    &.right {
        left: auto;
        right: 0;
        &:after {
            left: auto;
            right: 20px;
        }
    }



    &:after {
        background: #fff;
        border-bottom: 1px solid #d7d7d7;
        border-left: 1px solid #d7d7d7;
        content: '';
        height: 7px;
        margin-top: -4px;
        position: absolute;
        top: 3px;
        transform: translate(0, -50%) rotate(135deg);
        width: 7px;
        z-index: -10;

        @if $direction == left {
            left: 20px;
        } @else if $direction == right {
            right: 20px;
        }
    }

    .dropdownContent {
        padding-top: 5px;
    }

    .dropdownActions {
        align-items: center;
        border-top-color: #e4e7eb;
        border-top-style: solid;
        border-top-width: 1px;
        display: flex;
        justify-content: space-between;
        padding: 0.75rem 0 0;
        margin: 0.6rem 0 0;

        .left,
        .right {
            align-items: center;
            display: flex;
            justify-content: flex-start;
        }

        .right {
            justify-content: flex-end;
        }

        .buttonSecondary {
            margin-right: 0.8rem;
            font-weight: 300;
        }
    }
}

@mixin fontNormal {
    font-family: futura-pt, sans-serif;
    font-weight: 400;
}

@mixin fontBold {
    font-family: 'futura-pt-bold', sans-serif;
    font-weight: 700;
}

@mixin fontExtraBold {
    font-family: 'futura-pt-bold', sans-serif;
    font-weight: 800;
}

@mixin titleH1 {
    font-family: futura-pt-bold, sans-serif;
    font-size: $font-size-xxl;
    font-style: normal;
    font-weight: 700;
    line-height: 1.2;
    margin: 0 0 0.9rem;
}

@mixin titleH2 {
    font-family: futura-pt-bold, sans-serif;
    font-size: $font-size-xl;
    font-style: normal;
    font-weight: 700;
    line-height: 1.2;
    margin: 0 0 1rem;
}

@mixin titleH3 {
    font-family: futura-pt-bold, sans-serif;
    font-size: $font-size-l;
    font-style: normal;
    font-weight: 700;
    line-height: 1.2;
    margin: 1rem 0;
}

@mixin titleH4 {
    font-family: futura-pt-bold, sans-serif;
    font-size: $font-size-normal;
    font-style: normal;
    font-weight: 700;
    margin: 0 0 1rem;
}

@mixin titleH5 {
    font-family: futura-pt, sans-serif;
    font-size: $font-size-normal;
    font-weight: 400;
    line-height: 1.5;
    margin: 0 0 1rem;
}

@mixin bouton {
    align-items: center;
    border-radius: 2rem;
    color: #fff;
    display: inline-flex;
    font-size: $font-size-normal;
    font-weight: 500;
    justify-content: center;
    line-height: $font-size-l;
    padding: 0.4rem 0.8rem;
    transition: all 0.2s ease-in-out;
    vertical-align: middle;
}

@mixin text-truncate($max-lines) {
    display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: $max-lines;
    -webkit-box-orient: vertical;
}

@mixin body {
    margin: 0 auto;
    max-width: calc(1156px - #{$grid-column-gap-mobile} - #{$grid-column-gap-mobile});
    padding: 0 $grid-column-gap-mobile;

    @media #{$mq-medium-and-over} {
        max-width: 1152px;
        padding: 0;
    }
}
