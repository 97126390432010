.playIconInTo{
    &:after,
    &:before{
        align-items: center;
        display: flex;
        justify-content: center;
        position: absolute;
        text-align: center;
        transition: all 0.2s ease-in-out;
    }

    &:after{
        border-color: transparent transparent transparent $couleur-primaire-regular;
        border-style: solid;
        border-width: 8px 0 8px 12px;
        bottom: 19px;
        content: "";
        height: 0;
        right: 20px;
        text-align: center;
        transition: all 0.2s ease-in-out;
        width: 0;

        @media #{$mq-medium-and-over} {
            border-width: 13px 0 13px 18px;
            bottom: 20px;
            right: 21px;
        }
    }

    &:before{
        background-color: rgba(255,255,255,.8);
        border-radius: 50%;
        bottom: 8px;
        content: "";
        height: 40px;
        right: 8px;
        transition: all 0.2s ease-in-out;
        width: 40px;

        @media #{$mq-medium-and-over} {
            height: 50px;
            width: 50px;
        }
    }

    &:focus,
    &:hover{
        &:after{
            border-left-color: $couleur-primaire-dark;
        }
        &:before{
            background-color: $couleur-base-blanc;
        }
    }
}