// couleurs raw
$couleur-base-tq: #000d5c;
$couleur-base-blanc: #fff;
$couleur-base-noir: #000;

$couleur-alerte-rouge: #e01a00;
$couleur-alerte-jaune: #e89806;
$couleur-alerte-vert: #4ea400;
$couleur-alerte-blue: #0081cb;

//couleurs guide style
$couleur-primaire-regular: #009a74;
$couleur-primaire-dark: #005b5f;

$couleur-secondaire-regular: #e66e6a;
$couleur-accent-regular: #77ba35;

$couleur-neutre-light: #f2f2f2;
$couleur-neutre-regular: #e5e5e5;
$couleur-neutre-dark: #c1c1c1;

$couleur-alerte-erreur: #e01a00;
$couleur-alerte-avertissement: #e89806;
$couleur-alerte-succes: #4ea400;
$couleur-alerte-info: #0081cb;

$couleur-body-bg: #fff;
$couleur-card-bg: #eeeeee;
$couleur-text-light: #858585;
$couleur-text-regular: #000;
$couleur-text-dark: #4c4c4c;

$couleur-quiz-green: #73b62f;
$couleur-quiz-dark-green: #005a5f;
$couleur-quiz-red: #a31d0b;
$couleur-quiz-pink: #ed7167;

// sera convertie en objet genre
// couleur.primaire.regular
// couleur.neutre.light
// via src/styles/variables.js
:export {
    couleur_base_tq: $couleur-base-tq;

    couleur_base_blanc: $couleur-base-blanc;
    couleur_base_noir: $couleur-base-noir;

    couleur_primaire_regular: $couleur-primaire-regular;
    couleur_primaire_dark: $couleur-primaire-dark;

    couleur_secondaire_regular: $couleur-secondaire-regular;

    couleur_accent_regular: $couleur-accent-regular;

    couleur_neutre_light: $couleur-neutre-light;
    couleur_neutre_regular: $couleur-neutre-regular;
    couleur_neutre_dark: $couleur-neutre-dark;

    couleur_alerte_erreur: $couleur-alerte-erreur;
    couleur_alerte_avertissement: $couleur-alerte-avertissement;
    couleur_alerte_succes: $couleur-alerte-succes;
    couleur_alerte_info: $couleur-alerte-info;

    couleur_body_bg: $couleur-body-bg;
    couleur_text_light: $couleur-text-light;
    couleur_text_regular: $couleur-text-regular;
    couleur_text_dark: $couleur-text-dark;
}

//  font sizes
$font-size-base: 18px;
$font-size-tiny: 0.8rem;
$font-size-small: 0.9rem;
$font-size-normal: 1rem;
$font-size-l: 1.2rem;
$font-size-xl: 1.5rem;
$font-size-xxl: 1.8rem;

// screen w dimenson
$size-max-small: 591px;
$size-max-mobile: 768px;
$size-min-desktop: 1024px;
$size-min-large-desktop: 1440px;
$size-max-body: 1170px;

// Media queries

$mq-small-only: 'only screen and (min-width: 0) and (max-width: #{$size-max-mobile})';
$mq-medium-only: 'only screen and (min-width: #{$size-max-mobile}) and (max-width: #{$size-min-desktop})';
$mq-large-only: 'only screen and (min-width: #{$size-min-desktop}) and (max-width: #{$size-min-large-desktop})';

$mq-small-and-over: 'only screen and (min-width: #{$size-max-small})';
$mq-medium-and-over: 'only screen and (min-width: #{$size-max-mobile})';
$mq-large-and-over: 'only screen and (min-width: #{$size-min-desktop})';
$mq-xl-and-over: 'only screen and (min-width: #{$size-min-large-desktop})';

$mq-body-and-over: 'only screen and (min-width: #{$size-max-body})';

$grid-column-gap-mobile: 10px;
$grid-column-gap: 25px;

$padding-col-recette: 4rem;

$liste-fitres-tags-item-height: 44px;
$liste-fitres-tags-max-lines: 2;

$cubic-ease: cubic-bezier(.25, 0, .2, 1);